import React, { useState } from "react";
import "../../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "../../../Assets/css/plansPricingSeo.css";
import '../../../Assets/css/b2c.css'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from "react-tooltip";
import paypal from "../../../Assets/images/payment-getway-img/payPal.png";
import stripe from "../../../Assets/images/payment-getway-img/stripe.png";
import authorize from "../../../Assets/images/payment-getway-img/authorize.png";
import square from "../../../Assets/images/payment-getway-img/square.png";
import cardKnox from "../../../Assets/images/payment-getway-img/cardKnox.png";
import clover from "../../../Assets/images/payment-getway-img/clover.png";

function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const B2C = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const [currency,setCurrency] = useState("INR");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leading B2C Website Development Company | Belgium WebNet</title>
        <meta
          name="description"
          content="Acquire Belgium WebNet's B2B website development solutions and expertise to expand your jewelry business and achieve effective results. Book an appointment!"
        ></meta>
        <meta name="keywords" content="b2b website development company"></meta>
      </Helmet>
      <section className="bwn_b2c_pricing_section">
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    B2C Website Development
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing position-relative">
              {/* <div className="bwn_confit">
                    {isExploding && <ConfettiExplosion   force={.2}  duration= {3000}  particleCount={60} width={1000} height={100}/>}
              </div> */}
              
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>
              </div>
              <div className="bwn_first_month_free_text text-center">
                <div className="position-relative">
                  <button className="bwn_get_on_month_free_button">Annually Get 1 Month Free</button>
                  {/* <Image src={ArrowImpImg}></Image> */}
                </div>
              </div>
              {/* Monthly tab starting */}
            {window.location.pathname.includes("-inr") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table" hover>
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 10000 : 150} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-inr") && currency == "INR"  ? "₹75000" : '$1499'}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 15000 : 250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹100000" : "$2999"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 20000: 350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR"  ? "₹125000" :"$3999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                            Homepage Design
                            </td>
                            <td>
                              <div>
                              Predefined Theme
                              </div>
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              Theme Customization
                               <sapn>
                                <a className="my-anchor-element">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>We can design the homepage using any theme from the Belgium WebNet portfolio, with the option to combine components from multiple themes included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                              
                            </td>
                            <td>
                              <div>
                              Fully Customize Homepage
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                              {/* <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div> */}
                              Admin Panel Upload
                            </td>
                            <td>
                            1 Supplier & Admin Panel Upload
                            </td>
                            <td>
                            2 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                                1 Supplier
                            </td>
                            <td>
                                2 Suppliers
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                                1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            1 Supplier
                            </td>
                            <td>
                            2 Suppliers
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                            Admin Panel Upload
                            </td>
                            <td>
                            
                            <div className="bw_b2c_tool_tip_box">
                            1 Supplier Static & Admin Panel Upload
                               <sapn>
                                <a className="my-anchor-element2">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element2" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Admin panel watch uploads and a one-time supplier upload are included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                            1 Supplier Live & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                            </td>
                            <td>
                                <div className="bw_pricing_checkicon">
                                    <FiCheck />
                                  </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                    <FiCheck />
                                  </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                    <FiCheck />
                                  </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                    <FiCheck />
                                  </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                    <FiCheck />
                                  </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway</td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element3">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element3" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>                 
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element4">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element4" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column', padding: "5px 0" }}>
                                <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                  <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={square} alt="square" />
                                    <h6>Square</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={cardKnox} alt="cardKnox" />
                                    <h6>Card Knox</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={clover} alt="clover" />
                                    <h6>Clover</h6>
                                  </div>                                    
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                            2 Gateways
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            1 Gateway
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            Instagram
                            </td>
                            <td>
                            Instagram & Facebook
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Offline
                            </td>
                            <td>
                            Static Google Reviews & Offline
                            </td>
                            <td>
                            Live Google Reviews, Yelp & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                            <td>
                            Google Translate
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                        
                            <div className="bw_b2c_tool_tip_box">
                            2 Currencies (Admin Managed Rate)
                               <sapn>
                                <a className="my-anchor-element1">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element1" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Multi-currency support with 2 currencies and admin-managed exchange rates is included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                            3 Currencies with Live API
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Monthly
                            </td>
                            <td>
                            Bi-Monthly
                            </td>
                            <td>
                            Weekly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            Moderate 
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label=" RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 10000 : 150} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹" :"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                  {window.location.pathname.includes("-inr") && currency == "INR"  ? "₹75000" : "$1499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Homepage Design
                            </td>
                            <td>
                              <div>
                              Predefined Theme
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                            Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr> 
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                                Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway</td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element3">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element3" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>                 
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Social Media Feed Live Integration
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Monthly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          {/* <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Monthly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 15000 : 250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               { window.location.pathname.includes("-inr") && currency == "INR"  ? "₹100000" :"$2999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Homepage Design
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              Theme Customization
                               <sapn>
                                <a className="my-anchor-element">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>We can design the homepage using any theme from the Belgium WebNet portfolio, with the option to combine components from multiple themes included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                            1 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                            1 Supplier Static & Admin Panel Upload
                               <sapn>
                                <a className="my-anchor-element2">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element2" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Admin panel watch uploads and a one-time supplier upload are included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway</td>

                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element4">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element4" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column', padding: "5px 0" }}>
                                <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                  <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={square} alt="square" />
                                    <h6>Square</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={cardKnox} alt="cardKnox" />
                                    <h6>Card Knox</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={clover} alt="clover" />
                                    <h6>Clover</h6>
                                  </div>                                    
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>

                            <td>
                            Instagram
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Static Google Reviews & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                            2 Currencies (Admin Managed Rate)
                               <sapn>
                                <a className="my-anchor-element1">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element1" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Multi-currency support with 2 currencies and admin-managed exchange rates is included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>

                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Bi-Monthly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                            Moderate 
                            </td>
                          </tr>
                          
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM
                              </h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 20000 :350} duration={0} className="bwn_price_count_up"  prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {window.location.pathname.includes("-inr") && currency == "INR"  ? "₹125000": "$3999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Homepage Design
                            </td>
                            <td>
                            Fully Customize Homepage
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                            2 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                            2 Suppliers
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>

                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                            2 Suppliers
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                            1 Supplier Live & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway </td>

                            <td>
                            2 Gateways
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                            1 Gateway
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>
                            <td>
                            Instagram & Facebook
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Live Google Reviews, Yelp & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency

                            </td>

                            <td>
                            3 Currencies with Live API
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Weekly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                         
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                <Row className="w-100 m-auto">
              
                    <Col sm={12} md={12} lg={12}>
                    <h6 className="mb-2"><strong>Note:</strong></h6>
                    <div className="bw_green_djdinr_list_style pl-0">
                <p><strong>Please refer to the pricing below for integrating each additional Diamond Supplier, Jewelry Supplier, or Payment Gateway Portal.</strong>  </p>  
              </div>
              
                    <Table className="bw_pricing_note_table mt-4 mb-4" hover>
                      <thead >
                        <tr>
                          <th style={{ borderBottom: "1px solid #dee2e6", backgroundColor: "#e6ebf78c" }}>Particular</th>
                          <th style={{ borderBottom: "1px solid #dee2e6", backgroundColor: "#e6ebf78c"}}>One Time Charge</th>
                        </tr>
                      </thead>
                      <tbody className="border-bottom">
                        <tr>
                          <td>For any additional Diamond/Gemstone/Fancy Color Stone Supplier Integration</td>
                          <td>{window.location.pathname.includes("-inr") && currency == "INR"  ? "₹15000" :"$750"} each.</td>
                        </tr>
                        <tr>
                          <td>For any additional Jewelry Supplier Integration</td>
                          <td>{window.location.pathname.includes("-inr") && currency == "INR"  ? "₹25000" :"$1500"} Onwards.</td>
                        </tr>
                        <tr>
                          <td>For any additional Payment Gateway Integration</td>
                          <td> {window.location.pathname.includes("-inr") && currency == "INR"  ? "₹15000" :"$750"} Each.</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={12} md={12} lg={12}>
                    <ul className="bw_green_djdinr_list_style pl-4">
                      <li><strong>For requirements beyond the scope of our listed plans, please <Link to="/contacts" className="text-dark text-decoration-underline"><storng>Contact Us</storng></Link>  to discuss a customized Enterprise Plan.</strong></li>
                      <li>For Diamonds, Fancy Color Diamonds, and Gemstone integration, each supplier is limited to 50,000 stones. For higher stone counts, a custom quote will be provided.</li>
                      <li>
                        Marketplaces like Nivoda, Rapnet, VDB, Polygon, Idex, and Get Diamonds will be considered as one supplier each under your plan.
                      </li>
                     
                    </ul>
                    </Col>
                </Row>
                
              </TabPanel>
              {/* Monthly tab ending */}
              {/* Annual tab starting */}
              <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table" hover>
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 110000 : 1650} duration={.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-inr") && currency == "INR" ? "₹75000" : "$1499"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={ window.location.pathname.includes("-inr") && currency == "INR"  ? 165000 : 2750} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p> 
                                <h4>{ window.location.pathname.includes("-inr") && currency == "INR"  ? "₹100000" : "$2999"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={ window.location.pathname.includes("-inr") && currency == "INR"  ? 220000: 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-inr") && currency == "INR"  ? "₹125000": "$3999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              Homepage Design
                            </td>
                            <td>
                              <div>
                              Predefined Theme
                              </div>
                            </td>
                            <td>
                              <div>
                              <div className="bw_b2c_tool_tip_box">
                              Theme Customization
                               <sapn>
                                <a className="my-anchor-element">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>We can design the homepage using any theme from the Belgium WebNet portfolio, with the option to combine components from multiple themes included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                              Fully Customize Homepage
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                     
Jewelry Integration
                            </td>
                            <td>
                               
 	
Admin Panel Upload
                            </td>
                            <td>
                            1 Supplier & Admin Panel Upload
                            </td>
                            <td>
                              
 	
2 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            
 	
Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            1 Supplier
                            </td>
                            <td>
                            2 Suppliers

                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)

                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             
 	
Gemstones Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                              1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                               
 	
2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                               
 	
Watch Integration
                            </td>
                            <td>
                              <div>
                               
 	
Admin Panel Upload
                              </div>
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                            1 Supplier Static & Admin Panel Upload
                               <sapn>
                                <a className="my-anchor-element2">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element2" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Admin panel watch uploads and a one-time supplier upload are included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                              <div>
                              1 Supplier Live & Admin Panel Upload
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Ring Builder</td>
                            <td>
                               <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Pendant Builder </td>
                            <td>
                               <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Men's Band Builder </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Stud Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Payment Gateway</td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element3">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element3" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>                 
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element4">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element4" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column', padding: "5px 0" }}>
                                <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                  <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={square} alt="square" />
                                    <h6>Square</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={cardKnox} alt="cardKnox" />
                                    <h6>Card Knox</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={clover} alt="clover" />
                                    <h6>Clover</h6>
                                  </div>                                    
                                </div>
                              </div>                  
                              </Tooltip>
                                </div>
                            </td>
                            <td>
                              <div>
                              2 Gateways
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Finance Platform</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            1 Gateway
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Social Media Feed Live Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            Instagram
                            </td>
                            <td>
                            Instagram & Facebook
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                        	
Reviews Integration
                            </td>
                            <td>
                            Offline
                            </td>
                            <td>
                            Static Google Reviews & Offline
                            </td>
                            <td>
                            Live Google Reviews, Yelp & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                           
Multi-Language

                            </td>
                            <td>
                              <div>
                              Google Translate
                              </div>
                            </td>
                            <td>
                              <div>
                              Google Translate
                              </div>
                            </td>
                            <td>
                              <div>
                              Google Translate
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                           	
Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                              <div className="bw_b2c_tool_tip_box">
                            2 Currencies (Admin Managed Rate)
                               <sapn>
                                <a className="my-anchor-element1">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element1" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Multi-currency support with 2 currencies and admin-managed exchange rates is included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>                          
3 Currencies with Live API
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             
 	
Insta shop integration in Admin Panel
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                   
Website Cloud backup
                            </td>
                            <td>
                            Monthly
                            </td>
                            <td>
                            Bi-Monthly
                            </td>
                            <td>
                            Weekly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                           
Dedicated Project Manager
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">                             
Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            Moderate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label=" RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={ window.location.pathname.includes("-inr") && currency == "INR"  ? 110000 :1650} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                { window.location.pathname.includes("-inr") && currency == "INR"  ? "₹75000" : "$1499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              
 	
Homepage Design
                            </td>
                            <td>
                              
 	
Predefined Theme
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                           
Jewelry Integration
                            </td>
                            <td>
                            Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                              
Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                            
Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                              
Gemstones Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">                             	
Watch Integration
                            </td>
                            <td>
                            Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> 
   Ring Builder
   </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> 
 	
   Pendant Builder
   </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway </td>
                            <td>
                              <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element3">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element3" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>                 
                                </div>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Monthly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                        
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={ window.location.pathname.includes("-inr") && currency == "INR"  ? 165000 :2750} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {  window.location.pathname.includes("-inr") && currency == "INR"  ? "₹100000": "$2999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              
 	
Homepage Design
                            </td>
                            <td>
                              <div>
                              <div className="bw_b2c_tool_tip_box">
                              Theme Customization
                               <sapn>
                                <a className="my-anchor-element">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>We can design the homepage using any theme from the Belgium WebNet portfolio, with the option to combine components from multiple themes included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                              
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                            1 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                              <div>
                              1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                            <div className="bw_b2c_tool_tip_box">
                            1 Supplier Static & Admin Panel Upload
                               <sapn>
                                <a className="my-anchor-element2">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element2" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Admin panel watch uploads and a one-time supplier upload are included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway</td>

                            <td>
                            <div className="bw_b2c_tool_tip_box">
                              1 Gateway
                               <sapn>
                                <a className="my-anchor-element4">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element4" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div style={{ display: 'flex', flexDirection: 'column', padding: "5px 0" }}>
                                <span>We can integrate up to one payment gateway, selected from our approved list.</span>
                                <div className="bw_payment_getway_imgs_box">
                                  <div className="bw_payment_getway_imgs">
                                    <img src={paypal} alt="paypal" />
                                    <h6> PayPal</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={stripe} alt="stripe" />
                                    <h6>Stripe</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={authorize} alt="authorize" />
                                    <h6>Authorize.net</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={square} alt="square" />
                                    <h6>Square</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={cardKnox} alt="cardKnox" />
                                    <h6>Card Knox</h6>
                                  </div>
                                  <div className="bw_payment_getway_imgs">
                                    <img src={clover} alt="clover" />
                                    <h6>Clover</h6>
                                  </div>                                    
                                </div>
                              </div>                  
                              </Tooltip>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>

                            <td>
                            Instagram
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Static Google Reviews & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                              <div>
                              Google Translate
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency

                            </td>
                            <td>
                              <div>
                              <div className="bw_b2c_tool_tip_box">
                            2 Currencies (Admin Managed Rate)
                               <sapn>
                                <a className="my-anchor-element1">
                                <IoIosInformationCircleOutline /></a></sapn>
                            
                              <Tooltip anchorSelect=".my-anchor-element1" place="top" opacity={1} style={{ backgroundColor: "#2c52b0", color: "#fff", fontSize: "12px" }} className="tooltip_width">
                              <div >
                                <span>Multi-currency support with 2 currencies and admin-managed exchange rates is included in this plan.</span>
                              </div>                 
                              </Tooltip>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                            <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Bi-Monthly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                            Moderate
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM
                              </h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR"  ? 220000: 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR"  ? "₹":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {window.location.pathname.includes("-inr") && currency == "INR"  ? "₹125000": "$3999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              
 	
Homepage Design
                            </td>
                            <td>
                              <div>
                              Fully Customize Homepage
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Jewelry Integration
                            </td>
                            <td>
                            2 Supplier & Admin Panel Upload
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Natural & Lab Grown Diamonds Integration
                            </td>
                            <td>
                            2 Suppliers
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Fancy Color Natural & Lab Grown Diamonds Integration (Dedicated Search Page)
                            </td>

                            <td>
                            1 Supplier
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Gemstones Integration
                            </td>
                            <td>
                              <div>
                              2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Watch Integration
                            </td>
                            <td>
                              <div>
                              1 Supplier Live & Admin Panel Upload
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Pendant Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Men's Band Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Stud Builder</td>

                            <td>
                            <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway</td>

                            <td>
                              <div>
                              2 Gateways
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Finance Platform</td>
                            <td>
                            1 Gateway
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Feed Live Integration</td>
                            <td>
                            Instagram & Facebook
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Reviews Integration
                            </td>
                            <td>
                            Live Google Reviews, Yelp & Offline
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Language
                            </td>
                            <td>
                            Google Translate
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Multi-Currency

                            </td>

                            <td>
                              <div>
                              3 Currencies with Live API
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Website Cloud backup
                            </td>
                            <td>
                            Weekly
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Priority Support
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                <Row className="w-100 m-auto">
              
              <Col sm={12} md={12} lg={12}>
              <h6 className="mb-2"><strong>Note:</strong></h6>
               <div className="bw_green_djdinr_list_style pl-0">
                <p><strong>Please refer to the pricing below for integrating each additional Diamond Supplier, Jewelry Supplier, or Payment Gateway Portal.</strong>  </p>  
              </div>
              <Table className="bw_pricing_note_table mt-4 mb-4" hover>
<thead >
  <tr>
    <th style={{ borderBottom: "1px solid #dee2e6", backgroundColor: "#e6ebf78c" }}>Particular</th>
    <th style={{ borderBottom: "1px solid #dee2e6", backgroundColor: "#e6ebf78c"}}>One Time Charge</th>
  </tr>
</thead>
<tbody className="border-bottom">
  <tr>
    <td>For any additional Diamond/Gemstone/Fancy Color Stone Supplier Integration</td>
    <td>{window.location.pathname.includes("-inr") && currency == "INR"  ? "₹15000" :"$750"} each.</td>
  </tr>
  <tr>
    <td>For any additional Jewelry Supplier Integration</td>
    <td>{window.location.pathname.includes("-inr") && currency == "INR"  ? "₹25000" :"$1500"} Onwards.</td>
  </tr>
  <tr>
    <td>For any additional Payment Gateway Integration</td>
    <td> {window.location.pathname.includes("-inr") && currency == "INR"  ? "₹15000" :"$750"} Each.</td>
  </tr>
</tbody>
</Table>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <ul className="bw_green_djdinr_list_style pl-4">
                <li><strong>For requirements beyond the scope of our listed plans, please <Link to="/contacts" className="text-dark text-decoration-underline"><storng>Contact Us</storng></Link>  to discuss a customized Enterprise Plan.</strong></li>
                <li>For Diamonds, Fancy Color Diamonds, and Gemstone integration, each supplier is limited to 50,000 stones. For higher stone counts, a custom quote will be provided.</li>
                <li>
                  Marketplaces like Nivoda, Rapnet, VDB, Polygon, Idex, and Get Diamonds will be considered as one supplier each under your plan.
                </li>
                
              </ul>
              </Col>
          </Row>
                
              </TabPanel>
              {/* Annual tab ending */}

            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(B2C);
